import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import GoogleMap from "./GoogleMap";
const InviteUser = ({ initialRecord }) => {
  const iuProps = useSelector((state) => state.manageUser);

  const teamProps = useSelector((state) => state.categoryR);
  const dispatch = useDispatch();
  const { ShowModal, invitingUser, initialValues } = iuProps;

  // ✅ Check if we are adding or editing
  const mode = initialRecord?.mode || "add";

  // ✅ Check if it's a Virtual Store (Store Code)
  const isVirtualStore = initialRecord?.role === "virtualStore" || !!initialRecord?.storeShortCode;

  // ✅ Ensure correct initial values for Add & Edit
  const sanitizedInitialValues = mode === "edit" ? { ...initialRecord } : {};

  const [addressObj, setAddressObj] = useState(null);
  const { Category } = teamProps;
  const fetchCategoryList = () => {
    dispatch(DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList"));

    if (initialValues && initialValues.completeAddress) {
      setAddressObj(initialValues.completeAddress);
    }
    if (!ShowModal) {
      setAddressObj(null);
    }
  };
  useEffect(fetchCategoryList, [ShowModal]);

  return (
    <div>
      <FormModal
        modalTitle={mode === "edit" ? "Update Vendor/ Store Code" : "Add Vendor / Store Code"}
        isShowButton={false}
        submitButtonText={mode === "edit" ? "Update" : "Add"}
        cancelButtonText="Cancel"
        apiMethod={mode === "edit" ? "PUT" : "POST"}
        apiName={mode === "edit" ? "editVendor" : "addVendor"}
        editApiMethod={mode === "edit" ? "PUT" : "POST"}
        editApiName={mode === "edit" ? "editVendor" : "addVendor"}
        apiLoaderName="addingUser"
        confirmLoading={invitingUser}
        ModalType="InviteUser_Modal"
        visible={ShowModal}
        initialValues={{
          ...sanitizedInitialValues,
          role: isVirtualStore ? "virtualStore" : "vendor",
          storeCategory: sanitizedInitialValues?.storeCategory || "",
          completeAddress: sanitizedInitialValues?.completeAddress || { address: "", zipCode: "", lat: "", lng: "" },
        }}
        initialRecord={initialRecord}
        extraFieldName={"completeAddress"}
        extraFieldValue={addressObj}
        address={addressObj?.address}
      >
        {/* Hidden Field to Ensure `role` is Sent in API */}
        <Form.Item name="role" initialValue={isVirtualStore ? "virtualStore" : "vendor"} hidden>
          <Input type="hidden" />
        </Form.Item>
        {/* First Name & Last Name (Hidden for Virtual Store) */}
        <Row className="gx-flex-row gx-px-0 gx-mx-0">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={!isVirtualStore ? [{ required: true, message: "Please input your First Name!" }] : []}
              hidden={isVirtualStore}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={!isVirtualStore ? [{ required: true, message: "Please input your Last Name!" }] : []}
              hidden={isVirtualStore}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Email & Phone Number (Hidden for Virtual Store) */}
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              className="lower-case-email"
              rules={!isVirtualStore ? [{ required: true, message: "Please input your email!" }, { type: "email" }] : []}
              hidden={isVirtualStore}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={!isVirtualStore ? [{ required: true, message: "Please input your phone!" }] : []}
              hidden={isVirtualStore}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Store Name & Store Code */}
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Store Name" name="storeName" rules={[{ required: true, message: "Please input your store name!" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Store Code" name="storeShortCode" rules={[{ required: true, message: "Please input your store's short code!" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Store Category Selection */}
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Store Category" name="storeCategory" rules={[{ required: true, message: "Please choose your category!" }]}>
              <Select>
                {Category &&
                  Category.map((Item) => (
                    <Select.Option key={Item?._id} value={Item?._id}>
                      {Item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Google Map for Address */}
        <GoogleMap setAddressObj={setAddressObj} ShowModal={ShowModal} addressObj={sanitizedInitialValues ? addressObj : null} />
      </FormModal>
    </div>
  );
};

export default InviteUser;
