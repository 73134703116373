const INIT_STATE = {
  Product: [],
  addingProduct: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  SubCategoryList: [],
  imagesModelVisible: false,
  imagesList: [],
  addressList: [],
  priceModelVisible: false,
  priceList: null,
  CardData: null,
  ShowModalEdit: false,
  Bulk_Upload_Modal: false,
  ScrappingItems: [],
  addScrappingDataSuccess: false,
  addScrappingDataError: false,
  addScrappingDataSpin: false,
  SelectedScrappingItems: [],
  ScrappingItemsToken: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "addMultipleProduct_SUCCESS": {
      return {
        ...state,
        ScrappingItems: [],
        addScrappingDataSuccess: true,
        addScrappingDataSpin: false,
      };
    }
    case "addMultipleProduct_FAILURE": {
      return {
        ...state,
        addScrappingDataError: true,
        addScrappingDataSpin: false,
      };
    }
    case "AddScrappingDataSpin_START": {
      return {
        ...state,
        addScrappingDataSpin: true,
      };
    }
    case "ResetScrapping": {
      return {
        ...state,
        ScrappingItems: [],
        addScrappingDataSuccess: false,
        addScrappingDataError: false,
        addScrappingDataSpin: false,
        getItemDetailSuccess: false,
      };
    }
    case "ResetScrappingInputValue": {
      return {
        ...state,
        getItemDetailSuccess: false,
        ResetScrappingInputValue: null,
      };
    }

    case "getItemDetailWithSessionId_SUCCESS": {
      return {
        ...state,
        getScrappingDataSpin: false,
        ScrappingItems: action.payload.Record,
        SelectedScrappingItems: [],
      };
    }
    case "getItemDetailWithSessionId_FAILURE": {
      return {
        ...state,
        getScrappingDataSpin: false,
      };
    }
    case "getItemDetail_SUCCESS": {
      return {
        ...state,
        getItemDetailSuccess: true,
        ScrappingItems: action.payload.Record,
        ScrappingItemsToken: action.payload.Record,
        SelectedScrappingItems: [],
      };
    }
    case "getItemDetail_FAILURE": {
      return {
        ...state,
        getScrappingDataSpin: false,
      };
    }
    case "getItemDetailSpin_START": {
      return {
        ...state,
        getScrappingDataSpin: true,
      };
    }

    case "add_product_scrapping": {
      let Record = addScrappingUniqProduct(
        state.SelectedScrappingItems,
        action.payload
      );
      return {
        ...state,
        SelectedScrappingItems: [...Record],
      };
    }

    case "updateProductStatus_SUCCESS": {
      const rec = getProductArray(state.Product, action.payload.Record);
      return { ...state, Product: [...rec] };
    }
    case "Show_Bulk_Modal": {
      return {
        ...state,
        Bulk_Upload_Modal: true,
      };
    }
    case "getVendorCards_SUCCESS": {
      return { ...state, CardData: action.payload.Record };
    }
    case "getSubCategory_SUCCESS": {
      return { ...state, SubCategoryList: action.payload.Record };
    }
    case "deleteProduct_SUCCESS": {
      const productArray = removeDeletedProductFromState(
        state.Product,
        action.payload.Record
      );
      return {
        ...state,
        Product: [...productArray],
        addingProduct: false,
        success: true,
        textMessage: "product deleted successfully",
      };
    }

    case "editProduct_SUCCESS": {
      const productArr = getProductArray(state.Product, action.payload.Record);
      return {
        ...state,
        initialValues: null,
        Product: [...productArr],
        addingProduct: false,
        success: true,
        textMessage: "product updated successfully",
      };
    }

    case "productList_SUCCESS": {
      return { ...state, Product: action.payload.Record };
    }

    case "addingProduct_START": {
      return { ...state, addingProduct: true, initialValues: null };
    }

    case "addNewProduct_SUCCESS": {
      if (JSON.parse(localStorage.getItem("userInfo")).role === "superAdmin") {
        return {
          ...state,
          Product: [action.payload.Record, ...state.Product],
        };
      } else {
        return { ...state };
      }
    }
    case "addProductForAdmin_SUCCESS":
    case "addProduct_SUCCESS": {
      return {
        ...state,
        Product: [action.payload.Record, ...state.Product],
        addingProduct: false,
        success: true,
        initialValues: null,
        textMessage: "product added successfully",
      };
    }
    case "addProduct_FAILURE": {
      return {
        ...state,
        addingProduct: false,
        success: false,
        error: true,
        textMessage: "some error occur while adding product. kindly try again",
      };
    }
    case "AddProduct_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "EditProduct_Modal": {
      return {
        ...state,
        ShowModalEdit: !state.ShowModalEdit,
        initialValues: !state.ShowModalEdit ? action.payload : null,
      };
    }
    case "ResetPassword_Modal": {
      return {
        ...state,
        ShowModalPassword: !state.ShowModalPassword,
        initialValues: action.payload,
      };
    }
    case "getAddress_SUCCESS": {
      return {
        ...state,
        addressList: action.payload.Record,
      };
    }

    case "getVendorsAndStoreCodes_SUCCESS":
      console.log("Received Data:", action.payload);  // 🔍 Debugging
      return {
        ...state,
        assignToOptions: action.payload.Record
          ? action.payload.Record.map((user) => ({
              label: user.name || user.storeShortCode, // Ensure name is not empty
              value: user._id,
            }))
          : [],
      };
    

    case "resetProductState": {
      return {
        ...state,
        addingProduct: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
      };
    }
    case "Images_Modal": {
      return {
        ...state,
        imagesModelVisible: !state.imagesModelVisible,
        imagesList: action.payload,
      };
    }
    case "togglePriceModal": {
      return {
        ...state,
        priceModelVisible: !state.priceModelVisible,
        priceList: action.payload ? action.payload : "",
      };
    }

    default: {
      return { ...state };
    }
  }

  function getProductArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedProductFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
  function removeObjectFromArray(arr, property, value) {
    const index = arr.findIndex((obj) => obj[property] === value);
    if (index !== -1) {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    }
    return arr.slice();
  }

  function addScrappingUniqProduct(oldData, newData) {
    let newArray = [...oldData];
    const existingItemIndex = newArray.findIndex(
      (item) => item.link === newData.link
    );
    if (existingItemIndex !== -1) {
      newArray = removeObjectFromArray(newArray, "link", newData.link);
    } else {
      newData["productSize"] = "singleSize";
      newArray.push(newData);
    }

    return newArray;
  }
};
